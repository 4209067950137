/* You can add global styles to this file, and also import other style files */
.text-xs{
    font-size: 13px;
  }
  
  .titles{
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
  
  
  }